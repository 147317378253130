.product__carousel {
	.carousel-control-prev,
	.carousel-control-next {
    color: white;
    width: 50px;
  }

	svg {
		width: 100px;
		height: 100px;
		stroke: white;
	}

	.slider {
		// height: 43vh;
		height: 200px;
		background-position: center;
		background-size: cover;

		@media (min-width: 576px) {
			height: 400px;
		}
	}
}

.product-info, {
	h1, h2, h3, h4, h5 {
    font-size: 1rem;
		color: $gray-900;
		font-weight: bold;
	}
}

.green-bg-box {
	padding: 2rem 2rem;
	background-color: $bg-color;
	margin-bottom: 30px;
	border-radius: 0.25rem;
}

.option-box {
	padding: 2rem 2rem;

	h1, h2, h3, h4, h5 {
    font-size: 1rem;
		color: $gray-900;
		font-weight: normal;
	}
}

.product-info__title {
	font-size: 1.5rem;
	margin-bottom: 13px;
	border-bottom: 2px solid $primary;
  padding-bottom: 5px;
}

.product__option-title {
	font-weight: bold;
	margin-bottom: 5px;
	// color: $primary-green;

	&--secondary {
		font-weight: bold;
		margin-bottom: 8px;
	}
}

.product__option-date-select {
	width: 200px;
}

.right-info-box {
	overflow: auto;

	&__detail{
		border-bottom: 1px solid $primary !important;
		max-height: calc(100vh - 15px - 90px - 200px);
		overflow: auto;
	}
}

.form-control-quantity {
	width: 50px
}
