.country__carousel {
	.carousel-control-prev,
	.carousel-control-next {
    color: white;
    width: 50px;
  }

	.carousel-control-prev {
		left: -50px;
	}

	.carousel-control-next {
		right: -50px;
	}

	svg {
		width: 40px;
		height: 40px;
		// stroke: white;
	}

	.card--hot-location {

		a {
	    background-color: rgba(88, 88, 88, 0.4);

	    &:focus,
	    &:hover,
	    &:active {
	      background-color: transparent;
	    }
	  }

	}

}
