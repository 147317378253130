.body-container {
	position: relative;
	padding-bottom: 40px;
	border-bottom: solid 50px #6c757d;
	min-height: 100vh;
}

main {
	background-color: white;
}


h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	color: $secondary;
}

h1 {font-size: 38px;}
h2 {font-size: 25px; margin-bottom: 25px;}

.bg-grey {background-color: $grey-light;}
.bg-green {background-color: $bg-color;}

a,
a.Link {
	color: $primary;
}

p {color: $color_p;}

.white {color: white;}
.red {color: red;}
.green {color: $primary;}

.code {
	background-color: $grey;
	border-radius: 7px;
	padding: 5px 15px;
  display: inline-block;
}

.hidden {
	display:none;
}

.relative {
	position: relative;
}

.slash {
	position: absolute;
	left: -2px;
  top: 7px;
}

.pt-30vh {
	padding-top: 30vh;
}

.inline {
	display: inline-block;
}

.text-normal {
	font-size: 15px;
}

.pre-line {
	white-space: pre-line;
}

.no-result {
	background-image: url($bg-search-no-result);
	background-repeat: no-repeat;
	background-position: center;
	opacity: 0.4;
	height: 330px;
	padding-top: 139px;
  padding-right: 37px;
}

.small-icon {
	width: 15px;
	padding-bottom: 2px;
}

.text-primary {
	color: $primary !important;
}

.text-primary-dark {
	color: $primary-green !important;
}

.text-lg {
	font-size: 1.5rem;
	line-height: 1.5rem;
}

svg.icon-xs {
	width: 16px;
	height: 16px;
	stroke: $primary;
}

.border-bottom, .border-right, .border-left, .border-top {
	border-color: $primary !important;
}

.div-center {
	position: relative;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.list-group-item.active,
.list-group-item-action:active {
	color: inherit;
	background-color: #f8f9fa;
	border-color: rgba(0, 0, 0, 0.125)
}

.text-line-through {
	text-decoration: line-through;
}

.black {
	color: black;
}
