.css-yk16xz-control {
	border-radius: 0 !important;
}

.css-1rhbuit-multiValue {
	background-color: $grey-light !important;
	border: 1px solid $border-color;
}

.css-1pahdxg-control {
	border-radius: 0 !important;
	border: 1px solid $border-color !important;
}
