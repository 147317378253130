.search-banner {
  background-size: cover;
  background-position: center;
  height: 450px;
  padding-top: 153px;

  h1 {
    color: #59b569;
  }
}

.card--hot-location {
  padding: 0;

  a {
    background-color: rgba(107, 170, 66, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
    transition: 0.15s;

    &:focus,
    &:hover,
    &:active {
      color: white;
      text-decoration: none;
      background-color: rgba(107, 170, 66, 0);
    }
  }
}
