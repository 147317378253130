ul.layer {
    margin: 0px 0px 0px 20px;
		padding-left: 0;
		list-style: none;

    li.layer-li {
      position: relative;
			padding-bottom: 18px;

      &:before{
          position: absolute;
          left: -15px;
          top: 0px;
          content: '';
          display: block;
          border-left: 1px solid #ddd;
          height: 1em;
          border-bottom: 1px solid #ddd;
          width: 10px;
      }

      &:after{
          position: absolute;
          left: -15px;
          bottom: -7px;
          content: '';
          display: block;
          border-left: 1px solid #ddd;
          height: 100%;
      }

    &.root{
				padding-bottom: 0;
        margin: 0px 0px 0px -20px;
        &:before{
          display: none;
        }

        &:after{
          display: none;
        }
    }

     &:last-child{
        &:after{ display: none }
      }
  }
}
