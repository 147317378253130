.page-login {
  // padding-top: 40vh;
  text-align: center;

  padding-top: 30vh;
  padding-bottom: 10vh;

  .card {
    margin: 0 auto;
  }
}
