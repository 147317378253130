/* General */
$screen-xl-min: 1200px;
$screen-lg-min: 992px;
$screen-md-min: 768px;
$screen-sm-min: 576px;
$screen-xs-min: 0;

/* Colors */
$primary: #6baa42;
$primary-green: #768e66;
$secondary: #6c757d;
$primary-hover: $secondary;

$grey: #ececec;
$grey-hover: #e3e1e1;

$grey-light: #f3f3f3;

$color_p: black;

$bg-color: #ecf4e7;

$border-color: #dee2e6;

$left-nav-width: 230px;

$read-blue: #3e92fb;

$wColor: #0e94d6;
$wBgColor: #e9f5f7;

$oneColor: #e68e21;
$oneBgColor: #fdf5e4;

$nav_height: 77px;
