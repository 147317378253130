.country-title {

	span {
		position: relative;
		background-color: white;
		z-index: 1;
		color: $secondary
	}

	&::after {
		border-bottom: 1px dotted $primary;
		content: '';
		position: absolute;
		top: 30px;
		left: 14px;
		right: -14px;
		margin-left: 6px;
	}
}


.country-jumbotron {
	padding-top: 70px;
	padding-bottom: 70px;
  background-color: #d9e2d4;
  margin-bottom: 21px;
  font-size: 40px;
  color: #6baa42;
  // text-align: center;
}
