.product-search-bar-wrapper {
	.form-control {
		background-color: white;
		border-radius: 0;
		padding: 0.375rem 0.75rem;
		height: calc(1.5em + 0.75rem + 2px);
	}

	.ComboBoxList {
		border-radius: 0;
    overflow: auto;
		max-height: 300px;
		z-index: 100;
		width: 140%;
		transform: translate3d(0, 38px, 0px) !important;
	}

	.ListItem {
		padding: 0;
	}

	.ComboBoxOptionText__Highlighter mark {
		color: $primary !important;
		padding: 0 !important;
	}

	.product-search-bar__loading {
		position: absolute;
    right: 25px;
    top: 11px;
	}

	.section-title {
    margin-top: 0;
    padding-left: 12px;
	}
}
