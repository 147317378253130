.product-card {
	@media (min-width: 576px) {
		max-width: calc(25% - 30px);
	}
	a {height: 100%}
}

.product-img-top {
	height: 200px;
	background-position: center;
	background-size: cover;
}

.hot-product-carousel {
	@media (min-width: 576px) {
	  .carousel-control-prev {
	    left: -40px;
	    color: black;
	    width: 30px;
	  }
	  .carousel-control-next {
	    right: -40px;
	    color: black;
	    width: 30px;
	  }
	}
}

.hot-product-carousel-laoding-placeholder {
  min-height: 300px;
  padding-top: 140px;
  text-align: center;
}
