.alert-msg-wrapper {
	position: fixed;
	top: 18px;
	left: calc(50% - 225px);
	z-index: 10000;
}

.alert-msg {
	// position: fixed;
	// top: 18px;
	// left: calc(50% - 225px);
	width: 450px;
	box-shadow: 0px 3px 9px 0px #00000026;
  // min-height: 50px;
}
