.btn {
	border-radius: 0;
}

.btn-primary,
.btn-primary:disabled {
	border-color: #2e6f03;
  background: $primary;

	&:hover, &:active, &.active {
		background-color: $primary-green !important;
		border-color: #2e6f03 !important;

		&:not(:disabled):not(.disabled).active:focus {
			box-shadow: none;
		}
	}
}

.btn-outline-primary {
	border-color: $primary;
	color: $primary;

	&:hover, &:active, &.active {
		background-color: $primary-green !important;
		border-color: $primary !important;

		&:not(:disabled):not(.disabled).active:focus {
			box-shadow: none;
		}
	}
}

a {
	color: $secondary;

	&:hover, &:active {
		color: $primary-hover;
	}
}

.btn-link {
	color: $primary;
	padding: 0;

	&:hover,
	&:active,
	&:focus {
		color: $primary-green;
	}
}

.btn--lg {
	font-size: 14px;
	height: 55px;
	min-width: 180px;
}

.btn-outline-light {
	&:hover, &:active {
		color: $primary;
	}
}

.btn--icon {
	background-repeat: no-repeat;
	background-position: center;
	border: none;
	background-color: transparent;
}

.loading-btn {
	position: relative;
	background-color: $secondary;
	color: #afafaf;

	span {
		position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
		color: white;
	}
}

.back-btn-wrapper {
	position: absolute;
	top: 5px;
}

.btn-none {
	background-color: transparent;
}
